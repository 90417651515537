import React from 'react';
import { Box } from '@mui/joy';
import { LegalEntityAvatar } from  '../Snippet'

const CellLegalEntityFlat = ({ row }) => {

    return (
        <Box className={"list-element-cell cell-legalentity"}>
            {
                row.legalentity_name && <LegalEntityAvatar  name={row?.legalentity_name} url={row?.legalentity_logo_url} withname={true}/>
            }
        </Box>
    );
}

export default CellLegalEntityFlat