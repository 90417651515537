import React from 'react';
import { Box } from '@mui/joy';
import { LegalEntityAvatar } from  '../Snippet'

const CellRenterFlat = ({ row }) => {

    return (
        <Box className={"list-element-cell cell-legalentity"}>
            {
                row.renter_name && <LegalEntityAvatar  name={row?.renter_name} url={row?.renter_logo_url} withname={true}/>
            }
        </Box>
    );
}

export default CellRenterFlat